import React, { useState, useEffect } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "semantic-ui-css/semantic.min.css";
import App from "./App";
import store from "./StateManagement/store";
import { Provider } from "react-redux";
import axiosInstance from "./axios/axios"; // Your custom axios instance

const root = document.getElementById("root");

const AppContainer = () => {
  const [apiResponse, setApiResponse] = useState(null);
  const token = localStorage.getItem("token");

  function triggerPageLoadActions() {
    loadFavicon();
    loadEssentialResources();
    loadExternalResources();
  }

  // Functions to load resources as needed (same as in index.html)
  function loadFavicon() {
    const favicon = document.createElement("link");
    favicon.rel = "icon";
    favicon.href = "favicon-vozo.png";
    document.head.appendChild(favicon);
  }

  function loadEssentialResources() {
    // Load React
    const reactScript = document.createElement("script");
    reactScript.src = "https://unpkg.com/react@17/umd/react.production.min.js";
    document.head.appendChild(reactScript);

    // Load ReactDOM
    const reactDomScript = document.createElement("script");
    reactDomScript.src =
      "https://unpkg.com/react-dom@17/umd/react-dom.production.min.js";
    document.head.appendChild(reactDomScript);

    // Load jQuery
    const jqueryScript = document.createElement("script");
    jqueryScript.src =
      "https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js";
    document.head.appendChild(jqueryScript);


    const formioScript = document.createElement("script");
    formioScript.src =
      "https://cdn.form.io/formiojs/formio.full.min.js";
    document.head.appendChild(formioScript);


    const pageScript = document.createElement("script");
    pageScript.src =
      "https://cdn-in.pagesense.io/js/vozo/8dafafca6bba43a5b300c7177946fded.js";
    document.head.appendChild(pageScript);

    const productionScript = document.createElement("script");
    productionScript.src =
      "https://unpkg.com/react@17/umd/react.production.min.js";
    document.head.appendChild(productionScript);

  }

  // Function to load additional resources based on API response
  function loadExternalResources() {
    // Load Font Awesome CSS
    const fontAwesomeLink = document.createElement("link");
    fontAwesomeLink.rel = "stylesheet";
    fontAwesomeLink.href =
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css";
    document.head.appendChild(fontAwesomeLink);

    // Load Apple Touch Icon
    const appleTouchIconLink = document.createElement("link");
    appleTouchIconLink.rel = "apple-touch-icon";
    appleTouchIconLink.href = "%PUBLIC_URL%/logo192.png";
    document.head.appendChild(appleTouchIconLink);

    const formLink = document.createElement("link");
    formLink.rel = "stylesheet";
    formLink.href =
      "https://unpkg.com/formiojs@latest/dist/formio.full.min.css";
    document.head.appendChild(formLink);

    // const bootStrapLink = document.createElement("link");
    // bootStrapLink.rel = "stylesheet";
    // bootStrapLink.href =
    //   "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css";
    // document.head.appendChild(bootStrapLink);

    const formioLink = document.createElement("link");
    formioLink.rel = "stylesheet";
    formioLink.href =
      "https://cdn.form.io/formiojs/formio.full.min.css";
    document.head.appendChild(formioLink);

  
  }

  // Function to load the Clarity tracking script lazily with type="text/javascript"
  function loadClarityTracking() {
  
    if (window.clarity) {
      return; 
    }
  
    window.clarity = window.clarity || function () {
      (window.clarity.q = window.clarity.q || []).push(arguments);
    };
  
    const scriptElement = document.createElement('script');
    scriptElement.async = true;
    scriptElement.src = "https://www.clarity.ms/tag/nv7yzev35o"; 
  
    const firstScript = document.getElementsByTagName('script')[0]; 
    firstScript.parentNode.insertBefore(scriptElement, firstScript);
  }
  useEffect(() => {
    const fetchTransferData = async () => {
      const checkPath = !token ? "/api/vozo/transfer" : "/vozo/transfer";
      try {
        const response = await axiosInstance.get(checkPath);
        setApiResponse(response.data);
        if (parseInt(response.data) === 1) loadClarityTracking();
        if (parseInt(response.data) === 1 && token) triggerPageLoadActions();
      } catch (error) {
        console.error("API call failed:", error);
        setApiResponse(0);
      }
    };

    fetchTransferData();
  }, []);

  if (apiResponse === 0) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>403 Forbidden</h1>
        <p>You do not have permission to access this application.</p>
      </div>
    );
  }

  // If API response is valid (not 0), render the main app
  return (
    apiResponse === 1 && (
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    )
  );
};

// Rendering the AppContainer instead of App directly
render(<AppContainer />, root);
